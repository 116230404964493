import React, { useEffect, useState } from "react";
import { ref, onValue } from "firebase/database";
import {
  Grid,
  Toolbar,
  Button,
  Typography,
  Modal,
  Backdrop,
  Fade,
  MenuItem,
  Select,
} from "@material-ui/core";
import { BsFillLightningFill } from "react-icons/bs";
import logo from "../../assets/votpng.png";
import WalletButton from "./components/WalletButton";
import cvxLogo from "../../assets/cvxLogo.png";
import votBoxOutlined from "../../assets/votBoxOutlined.png";
import crvToken from "../../assets/crvToken.png";
import { ethers } from "ethers";
import { snapshotAddress, snapshotABI } from "../../constants";
import { useStylesDark, useStylesLight } from "../../Styles/MobileHeaderStyles";
import { Link } from "react-router-dom";
import fxnLogo from "../../assets/fxnLogo.png";

const menuItems = [
  {
    value: "/",
    name: "Curve",
    logo: cvxLogo,
    smallLogo: crvToken,
  },
  { value: "/fxn", name: "f(x)", logo: cvxLogo, smallLogo: fxnLogo },
];

const Header = (props) => {
  const classesLight = useStylesLight();
  const classesDark = useStylesDark();
  const [classes, setClasses] = useState(classesDark);
  const [openModal, setOpenModal] = useState(false);
  const [delegateText, setDelegateText] = useState("Delegate");
  const [buttonText, setButtonText] = useState("Claim Rewards");
  const [addressLockedRef, setAddressLockedRef] = useState(null);
  const [refreshAddressCheck, setRefreshAddressCheck] = useState(0);
  const [connectedNetwork, setConnectedNetwork] = useState(0);
  const [addressPendingAmount, setaddressPendingAmount] =
    useState("Loading...");
  const [addressVotingAmount, setAddressVotingAmount] = useState("Loading...");
  const [refreshDB, setRefreshDB] = useState(0);
  const [totalLockedRef, setTotalLockedRef] = useState();
  const [totalLockedAmount, setTotalLockedAmount] = useState("Loading...");
  const [totalveCRVRef, setTotalveCRVRef] = useState();
  const [totalveCRVAmount, setTotalveCRVAmount] = useState("0.00");
  const [totalDelegatedRef, setTotalDelegatedRef] = useState();
  const [totalDelegatedAmount, setTotalDelegatedAmount] =
    useState("Loading...");

  // theme setting
  useEffect(() => {
    if (props.ctheme === "light") {
      setClasses(classesLight);
    } else {
      setClasses(classesDark);
    }
  }, [props.ctheme]);

  // check provider network
  useEffect(() => {
    if (props.provider !== undefined) {
      if (props.provider._network !== undefined) {
        setConnectedNetwork(props.provider._network.chainId);
        if (props.provider._network.chainId !== 1) {
          setDelegateText("Connect to Ethereum");
        } else {
          setDelegateText("Delegate");
        }
      } else if (connectedNetwork > -100) {
        setTimeout(() => {
          setConnectedNetwork(connectedNetwork - 1);
        }, 100);
      }
    }
  }, [props.provider, connectedNetwork]);
  //get address amount from database
  useEffect(() => {
    async function getRefs() {
      if (props.address) {
        const addressLockedRef = ref(
          props.db,
          "delegation/users/" + props.address.toUpperCase()
        );
        setAddressLockedRef(addressLockedRef);
      } else {
        setAddressLockedRef(null);
        setRefreshAddressCheck(refreshAddressCheck + 1);
      }
    }
    async function getInfoFromDatabase() {
      if (props.db && props.address) {
        try {
          if (addressLockedRef !== null) {
            onValue(addressLockedRef, (snapshot) => {
              const data = snapshot.val();
              if (data !== null) {
                setaddressPendingAmount(
                  ethers.utils.commify(
                    (Number(data.locked) - Number(data.balance))
                      .toFixed(2)
                      .toString()
                  )
                );
                setAddressVotingAmount(
                  ethers.utils.commify(
                    Number(data.balance).toFixed(2).toString()
                  )
                );
              } else {
                console.log("Couldn't get address amount from db");
                setAddressLockedRef(null);
              }
            });
          } else {
            setRefreshAddressCheck(refreshAddressCheck + 1);
          }
        } catch {
          console.log("Couldn't get info from database");
          setRefreshAddressCheck(refreshAddressCheck + 1);
        }
      }
    }
    if (refreshAddressCheck < 50 && props.address) {
      try {
        getRefs();
        getInfoFromDatabase();
      } catch {
        if (props.provider !== undefined) {
          if (props.provider._network !== undefined) {
            console.log(props.provider._network.chainId);
          }
        }
        console.log();
        setRefreshAddressCheck(refreshAddressCheck + 1);
      }
    } else {
      console.log("Couldn't run getRefs / getInfoFromDatabase");
    }
  }, [props.db, props.provider, props.address, refreshAddressCheck]);

  //get amounts from database
  useEffect(() => {
    async function getRefs() {
      const totalDelegatedRef = ref(props.db, "delegation/totalCVX");
      setTotalDelegatedRef(totalDelegatedRef);
      const totalLockedRef = ref(props.db, "api/data/totalSupply");
      setTotalLockedRef(totalLockedRef);
      const totalveCRVRef = ref(props.db, "api/data/vecrv");
      setTotalveCRVRef(totalveCRVRef);
    }
    async function getInfoFromDatabase() {
      if (props.db) {
        try {
          onValue(totalDelegatedRef, (snapshot) => {
            const data = snapshot.val();
            if (data !== null) {
              setTotalDelegatedAmount(
                ethers.utils.commify(Number(data).toFixed(0).toString())
              );
            } else {
              console.log("Couldn't get total delegated from db");
            }
          });
          onValue(totalLockedRef, (snapshot) => {
            const data = snapshot.val();
            if (data !== null) {
              setTotalLockedAmount(
                ethers.utils.commify((data / 10 ** 18).toFixed(0).toString())
              );
            } else {
              console.log("Couldn't get total locked from db");
            }
          });
          onValue(totalveCRVRef, (snapshot) => {
            const data = snapshot.val();
            if (data !== null) {
              setTotalveCRVAmount(
                ethers.utils.commify((data / 10 ** 18).toFixed(0).toString())
              );
            } else {
              console.log("Couldn't get vecrv amount from db");
            }
          });
        } catch {
          console.log("Couldn't get info from database");
          setRefreshDB(refreshDB + 1);
        }
      }
    }
    try {
      if (refreshDB < 30) {
        getRefs();
        getInfoFromDatabase();
      }
    } catch {
      console.log("Couldn't run getRefs / getInfoFromDatabase");
    }
  }, [props.db, props.provider, refreshDB]);

  const handleDelegate = async () => {
    const signer = props.provider.getSigner();
    const snapshotContract = new ethers.Contract(
      snapshotAddress,
      snapshotABI,
      signer
    );
    const tx = await snapshotContract.setDelegate(
      ethers.utils.formatBytes32String("cvx.eth"),
      "0xde1E6A7ED0ad3F61D531a8a78E83CcDdbd6E0c49"
    );
    await props.provider.sendTransaction(tx);
    setDelegateText("Succssesfully delegated!");
  };

  useEffect(() => {
    if (window.location.pathname === "/claim") {
      setButtonText("View Proposal");
    } else {
      setButtonText("Claim Rewards");
    }
  }, []);
  const handleHeaderButton = () => {
    if (buttonText === "Claim Rewards") {
      setButtonText("View Proposal");
    } else {
      setButtonText("Claim Rewards");
    }
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <div className={classes.header}>
        <Toolbar disableGutters className={classes.toolbar}>
          {/* Header logo and links */}
          <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={6}>
              <img alt="votium logo" className={classes.logo} src={logo} />
            </Grid>
            <Grid
              container
              xs={6}
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item className={classes.address}>
                <WalletButton
                  provider={props.provider}
                  connect={props.connect}
                  disconnect={props.disconnect}
                  address={props.address}
                  wallet={props.wallet}
                />
                <Grid item className={classes.switchButton}>
                  <Select
                    value={window.location.pathname}
                    onChange={(event) =>
                      (window.location.pathname = event.target.value)
                    }
                    className={classes.selectMenu}
                    MenuProps={{
                      classes: { paper: classes.selectMenuItems },
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    {menuItems.map((item) => (
                      <MenuItem
                        value={item.value}
                        className={classes.selectMenuItems}
                        style={{
                          display:
                            item.value === window.location.pathname
                              ? "none"
                              : "block",
                        }}
                      >
                        <Grid container direction="row" alignItems="center">
                          {item.name}
                          <img
                            alt={`${item.name} logo`}
                            className={classes.vlcvxLogo}
                            src={item.logo}
                          />
                          <img
                            alt={`${item.name} logo`}
                            className={classes.curveLogoSmall}
                            src={item.smallLogo}
                          />
                        </Grid>
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
        {/* Balances */}
        <Grid container justifyContent="center" alignItems="center">
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            md={8}
            xs={12}
            direction="column"
          >
            <Grid item>
              <Typography className={classes.stakedVotiText}>
                Eligible for Incentives
              </Typography>
              <Grid container direction="row" justifyContent="center">
                <Grid item>
                  <img
                    alt="snapshot-logo"
                    src={cvxLogo}
                    className={classes.stakedVotiLogo}
                  ></img>
                </Grid>
                <Grid item>
                  <Typography className={classes.stakedVotiAmount}>
                    {totalLockedAmount}
                  </Typography>
                </Grid>
                <Grid container direction="column">
                  <Grid item>
                    <Typography className={classes.totalText}>
                      {"≈ " + totalveCRVAmount + " veCRV"}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <div className={classes.delegatedSpacesTitle}>
                <div className={classes.headText}>
                  <div>
                    <img
                      src={votBoxOutlined}
                      alt="outlined"
                      className={classes.headImage}
                    />
                  </div>
                  <div className={classes.imgText}>
                    <Typography className={classes.textInside}></Typography>
                  </div>
                  <div className={classes.imgTextAmount}>
                    {window.location.pathname !== "/claim" ? (
                      <Link
                        to="/claim"
                        className={classes.claimButton}
                        variant="outlined"
                        onClick={handleHeaderButton}
                      >
                        {buttonText}
                      </Link>
                    ) : (
                      <Link
                        to="/"
                        className={classes.claimButton}
                        variant="outlined"
                        onClick={handleHeaderButton}
                      >
                        {buttonText}
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item>
              <Typography className={classes.delegatedAmountText}>
                Delegated Voting Power
              </Typography>
              <Grid container direction="row" justifyContent="center">
                {addressLockedRef !== null && (
                  <Grid item>
                    <img
                      alt="snapshot-logo"
                      src={cvxLogo}
                      className={classes.stakedCVXLogo}
                    ></img>
                  </Grid>
                )}
                {addressLockedRef === null ? (
                  connectedNetwork === 1 ? (
                    <>
                      <Grid item align="center">
                        <Button
                          classes={{
                            root: classes.delegateButton,
                            disabled: classes.delegateButtonDisabled,
                          }}
                          disableRipple
                          onClick={handleOpenModal}
                          endIcon={
                            <BsFillLightningFill fontSize="small"></BsFillLightningFill>
                          }
                          disabled={connectedNetwork !== 1}
                        >
                          {delegateText}
                        </Button>
                        <Modal
                          className={classes.modal}
                          open={openModal}
                          onClose={handleCloseModal}
                          closeAfterTransition
                          BackdropComponent={Backdrop}
                          BackdropProps={{
                            timeout: 500,
                          }}
                        >
                          <Fade in={openModal}>
                            <div className={classes.modalPaper}>
                              <Grid
                                container
                                direction="column"
                                alignItems="center"
                              >
                                <Grid item>
                                  <Typography className={classes.modalText}>
                                    To be eligible for the rewards, you have to
                                    be locked and delegated before the convex
                                    snapshot proposal began.
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Button
                                    className={classes.faqButton}
                                    onClick={() => {
                                      window.open(
                                        "https://docs.votium.app/faq/vlcvx-faq"
                                      );
                                    }}
                                  >
                                    Please read the FAQ before delegating
                                  </Button>
                                </Grid>
                                <Grid item>
                                  <Button
                                    className={classes.modalDelegateButton}
                                    disableRipple
                                    onClick={handleDelegate}
                                    endIcon={
                                      <BsFillLightningFill fontSize="small"></BsFillLightningFill>
                                    }
                                  >
                                    {delegateText}
                                  </Button>
                                </Grid>
                              </Grid>
                            </div>
                          </Fade>
                        </Modal>
                      </Grid>
                      <Grid container direction="column" alignItems="center">
                        <Grid item>
                          <Typography className={classes.totalTextUndelegated}>
                            {"total delegated: " +
                              totalDelegatedAmount +
                              " vlCVX"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item>
                        <Typography className={classes.connectText}>
                          Connect to Ethereum to delegate
                        </Typography>
                      </Grid>
                      <Grid container direction="column">
                        <Grid item>
                          <Typography className={classes.totalText}>
                            {"From all users: " + totalDelegatedAmount}
                          </Typography>
                        </Grid>
                      </Grid>
                    </>
                  )
                ) : (
                  <>
                    <Grid item>
                      <Typography className={classes.stakedVotiAmount}>
                        {addressVotingAmount}
                      </Typography>
                    </Grid>
                    {addressPendingAmount !== "0.0" ? (
                      <>
                        <Grid container direction="column">
                          <Grid item>
                            <Typography className={classes.pendingAmountText}>
                              Pending next round:{" "}
                              <span className={classes.pendingAmount}>
                                {addressPendingAmount}
                              </span>
                            </Typography>
                          </Grid>
                        </Grid>
                      </>
                    ) : (
                      <></>
                    )}

                    <Grid container direction="column">
                      <Grid item>
                        <Typography className={classes.totalText}>
                          {"out of a total " + totalDelegatedAmount}
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                )}
                <Grid item></Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Header;
